import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { NgIf } from '@angular/common'
import { Router, Event, NavigationEnd, RouterLink } from '@angular/router'
import { filter } from 'rxjs/operators'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDealerSearchComponent } from '@appShared/components/dealer-search/modal-dealer-search.component'
import { AppFacade } from '@appShared/services/app.facade'
import { DealerService } from '@appShared/services/dealer.service'
import { CommonService } from '@appShared/services/common.service'
import { GEN2_ROUTES } from '@appShared/gen2.routes'
import { environment, RouteModel } from '@appEnvironments/environment'

@Component({
   selector: 'app-nav-menu',
   templateUrl: './nav-menu.component.html',
   styleUrls: ['./nav-menu.component.less'],
   standalone: true,
   imports: [NgIf, RouterLink],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavMenuComponent {
   private _commonService = inject(CommonService)
   isAuthenticated = false
   isExpanded = false

   private _routes = environment.routes
   salesSchoolRegistrationRoute = this._routes.sales_school_registration
   remitOnlineRoute = this._routes.account.remittance

   private _appFacade = inject(AppFacade)
   private _dealerService = inject(DealerService)
   currentProfile = this._appFacade.currentProfile
   selectedDealer = this._dealerService.selectedDealer
   salesPortalUri = this._appFacade.salesPortalUri
   gen2Routes = GEN2_ROUTES

   constructor(
      private _modalService: NgbModal,
      router: Router
   ) {
      router.events
         .pipe(
            takeUntilDestroyed(),
            filter((routerEvent: Event) => routerEvent instanceof NavigationEnd)
         )
         .subscribe((routerEvent: NavigationEnd) => {
            const url = routerEvent.url.toLowerCase()
            this.isAuthenticated = !url.startsWith('/student-dealer')
         })
   }

   goToPortal(route?: RouteModel): void {
      this._appFacade.navigateToGen2(route?.uri)
   }

   collapse() {
      this.isExpanded = false
   }

   toggle() {
      this.isExpanded = !this.isExpanded
   }


   searchDealers() {
      const editLeadModalRef = this._modalService.open(
         ModalDealerSearchComponent,
         { scrollable: true }
      )
   }

   logout(): void {
      this._commonService.logout();
   }
}
