<!-- Navbar 2 - Bootstrap Brain Component -->
<nav class="navbar bsb-navbar bsb-navbar-caretv site-header" data-bs-theme="dark">
   <div class="container position-relative justify-content-center">
      <a class="navbar-brand" [routerLink]="['/']">
         <img src="/assets/images/swa-logo_white.png" class="full-logo" alt="Advantage 4 Dealers" />
         <img src="/assets/images/swa-logo-icon_white.png" class="icon" title="Advantage 4 Dealers" />
      </a>
      <button class="navbar-toggler border-0 position-absolute" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" class="bi bi-list" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
         </svg>
      </button>
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
         <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Menu</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
         </div>
         <div class="offcanvas-body">
            <ul class="navbar-nav justify-content-end flex-grow-1">
               <li class="nav-item menu-section pb-1 px-0">
                  <div class="section d-flex justify-content-between">
                     <a aria-current="page" [routerLink]="['/']" data-bs-dismiss="offcanvas" class="d-flex align-items-center gap-1 toggleActivity">
                        Home
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
                           <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
                        </svg>
                     </a>
                     <a class="d-flex align-items-center gap-1 pointable"
                        (click)="goToPortal(gen2Routes.settings)" data-bs-dismiss="offcanvas">
                        Settings
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                           <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
                           <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
                        </svg>
                     </a>
                     <a class="d-flex align-items-center gap-1 pointable"
                        (click)="goToPortal(gen2Routes.dealer_profile)" data-bs-dismiss="offcanvas">
                        Profile
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                           <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                           <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                        </svg>
                     </a>
                     <a href="#" (click)="logout()" data-bs-dismiss="offcanvas" class="logout d-flex align-items-center gap-2">
                        Logout
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                           <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
                           <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
                        </svg>
                     </a>
                  </div>
               </li>
               <li class="nav-item">
                  <hr />
               </li>
               <li class="nav-item"
                   *ngIf="currentProfile()?.canViewDealerSearch">
                  <div class="dropdown-divider"></div>
                  <span (click)="searchDealers()"
                        class="pointable dropdown-item" data-bs-dismiss="offcanvas">Search Dealers</span>
               </li>
               <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#!" id="my-business-dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">My Business</a>
                  <ul class="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="my-business-dropdown">
                     <li class="menu-section">
                        <div class="section link-list">
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.record_of_sale)" data-bs-dismiss="offcanvas">{{gen2Routes.record_of_sale.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.e_bar)" data-bs-dismiss="offcanvas">{{gen2Routes.e_bar.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.activity_reports)" data-bs-dismiss="offcanvas">{{gen2Routes.activity_reports.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.account_activity)" data-bs-dismiss="offcanvas">{{gen2Routes.account_activity.title}}</a>
                           <a class="dropdown-item" routerLink="{{remitOnlineRoute.uri}}" data-bs-dismiss="offcanvas">{{remitOnlineRoute.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.dealer_order_processing)" data-bs-dismiss="offcanvas">{{gen2Routes.dealer_order_processing.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.transfers)" data-bs-dismiss="offcanvas">{{gen2Routes.transfers.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.tax_summary)" data-bs-dismiss="offcanvas">{{gen2Routes.tax_summary.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.cancellations)" data-bs-dismiss="offcanvas">{{gen2Routes.cancellations.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.shipping_calculator)" data-bs-dismiss="offcanvas">{{gen2Routes.shipping_calculator.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.check_in)" data-bs-dismiss="offcanvas">{{gen2Routes.check_in.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.check_in_statement)" data-bs-dismiss="offcanvas">{{gen2Routes.check_in_statement.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.merchandise_returns)" data-bs-dismiss="offcanvas">{{gen2Routes.merchandise_returns.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.electronic_forms)" data-bs-dismiss="offcanvas">{{gen2Routes.electronic_forms.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.inventory)" data-bs-dismiss="offcanvas">{{gen2Routes.inventory.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.order_approval)" data-bs-dismiss="offcanvas">{{gen2Routes.order_approval.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.back_order_item)" data-bs-dismiss="offcanvas">{{gen2Routes.back_order_item.title}}</a>
                        </div>
                     </li>
                  </ul>
               </li>
               <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#!" id="summer-tools-dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Summer Set Up Tools</a>
                  <ul class="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="summer-tools-dropdown">
                     <li class="menu-section">
                        <div class="section link-list">
                           <a class="dropdown-item" routerLink="{{salesSchoolRegistrationRoute.uri}}" data-bs-dismiss="offcanvas">{{salesSchoolRegistrationRoute.title}}</a>
                           <a class="dropdown-item cursor-pointer" (click)="goToPortal(gen2Routes.headquarters_map)" data-bs-dismiss="offcanvas">{{gen2Routes.headquarters_map.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.host_family)" data-bs-dismiss="offcanvas">{{gen2Routes.host_family.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.unit_map)" data-bs-dismiss="offcanvas">{{gen2Routes.unit_map.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.host_family_placement)" data-bs-dismiss="offcanvas">{{gen2Routes.host_family_placement.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.pacesetter)" data-bs-dismiss="offcanvas">{{gen2Routes.pacesetter.title}}</a>
                        </div>
                     </li>
                  </ul>
               </li>
               <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#!" id="sales-leader-resources-dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Sales Leader Resources</a>
                  <ul class="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="summer-tools-dropdown">
                     <li class="menu-section">
                        <div class="section link-list">
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.reporting)" data-bs-dismiss="offcanvas">{{gen2Routes.reporting.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.check_in_statement)" data-bs-dismiss="offcanvas">{{gen2Routes.check_in_statement.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.cost_center_expenses)" data-bs-dismiss="offcanvas">{{gen2Routes.cost_center_expenses.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.cost_center_residuals)" data-bs-dismiss="offcanvas">{{gen2Routes.cost_center_residuals.title}}</a>
                        </div>
                     </li>
                  </ul>
               </li>
               <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#!" id="resources-dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Resources</a>
                  <ul class="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="resources-dropdown">
                     <li class="menu-section">
                        <div class="section link-list">
                           <a class="dropdown-item" href="{{gen2Routes.employee_resources.uri}}" data-bs-dismiss="offcanvas">{{gen2Routes.employee_resources.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.sw_stats_app)" data-bs-dismiss="offcanvas">{{gen2Routes.sw_stats_app.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.text_dealers)" data-bs-dismiss="offcanvas">{{gen2Routes.text_dealers.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.sw_capture_app)" data-bs-dismiss="offcanvas">{{gen2Routes.sw_capture_app.title}}</a>
                           <a class="dropdown-item" href="{{gen2Routes.faq.uri}}" data-bs-dismiss="offcanvas">{{gen2Routes.faq.title}}</a>
                           <a class="dropdown-item" (click)="goToPortal(gen2Routes.a4d_app)" data-bs-dismiss="offcanvas">{{gen2Routes.a4d_app.title}}</a>
                        </div>
                     </li>
                  </ul>
               </li>
               <!--<li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#!" id="accountDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Account</a>
                  <ul class="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="accountDropdown">
                     <li><a class="dropdown-item" routerLink="/{{salesSchoolRegistrationRoute.uri}}" data-bs-dismiss="offcanvas">Log in</a></li>
                     <li><a class="dropdown-item" href="#!" data-bs-dismiss="offcanvas">Lost Password?</a></li>
                     <li>
                        <hr class="dropdown-divider">
                     </li>
                     <li><a class="dropdown-item" href="#!" data-bs-dismiss="offcanvas">Sign up</a></li>
                  </ul>
               </li>-->
               <!-- EXAMPLE offcanvas sub-menu
               <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#!" id="servicesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
                  <ul class="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="servicesDropdown">
                     <li><a class="dropdown-item" href="#!" data-bs-dismiss="offcanvas">Writing</a></li>
                     <li><a class="dropdown-item" href="#!" data-bs-dismiss="offcanvas">SEO</a></li>
                     <li><a class="dropdown-item" href="#!" data-bs-dismiss="offcanvas">Web Design</a></li>
                     <li><a class="dropdown-item" href="#!" data-bs-dismiss="offcanvas">App Development</a></li>
                  </ul>
               </li>-->
               <!--<li class="nav-item">
                  <a class="nav-link" href="#!" data-bs-dismiss="offcanvas">About</a>
               </li>
               <li class="nav-item">
                  <a class="nav-link" href="#!" data-bs-dismiss="offcanvas">Contact</a>
               </li>-->
            </ul>
         </div>
      </div>
   </div>
</nav>

<!--<nav class="navbar navbar-expand-md navbar-dark site-header fixed-top">

   <div class="container">

      <a href="{{salesPortalUri()}}" class="btn btn-sm btn-success me-3" style="color:white">Return Adv4Dealers</a>

      <button class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
         <ul class="navbar-nav me-auto ms-md-2">
            <li *ngIf="!(selectedDealer())"
                class="nav-item active dropdown">
               <span *ngIf="currentProfile()?.canViewDealerSearch"
                     (click)="searchDealers()"
                     class="text-white pointable text-underline">Search Dealers</span>
            </li>
            <li *ngIf="selectedDealer()"
                class="nav-item dropdown selected-dealer">
               <a class="nav-link active dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{selectedDealer().contact.firstName}} {{selectedDealer().contact.lastName}} ( <span class="fw-bold">{{(selectedDealer().contact.sourceId)}}</span> )
               </a>
               <div class="dropdown-menu" aria-labelledby="dropdownDealer">
                  <div class="card mb-3 p-1">
                     <div class="row g-0">
                        <div class="col-md-4">
                           <img src="/api/dealer/{{selectedDealer().contact.sourceId}}/photo"
                                class="img-fluid img-rounded img-thumbnail outer-shadow" alt="..." />
                        </div>
                        <div class="col-md-8">
                           <div class="card-body p-1">
                              <h5 class="card-title">{{selectedDealer().contact.firstName}} {{selectedDealer().contact.lastName}}</h5>
                              <p class="card-text dealer-account-number">( {{selectedDealer().contact.sourceId}} )</p>
                              <p class="card-text"><small class="text-body-secondary">{{selectedDealer().contact.salesManagerName}}</small></p>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="text-center mt-2" *ngIf="currentProfile()?.canViewDealerSearch">
                     <button (click)="searchDealers()" class="btn btn-sm btn-secondary text-white">Search Dealers</button>
                  </div>
               </div>
            </li>
         </ul>
         <ul class="navbar-nav">
            <li class="nav-item dropstart">
               <a class="nav-link active dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  Menu
               </a>
               <ul class="dropdown-menu dropdown-menu-left">
                  <li>
                     <a tabindex="-1" href="{{salesPortalUri}}" class="dropdown-item">Adv4Dealers</a>
                  </li>
                  <li *ngIf="currentProfile()?.canViewPendingMerchandiseReturns">
                     <div class="dropdown-divider"></div>
                     <a tabindex="-1" [routerLink]="['/merchandise-returns/pending']" class="logout dropdown-item">Pending Returms</a>
                  </li>
                  <li><hr class="dropdown-divider"></li>

                  <ng-container *ngIf="selectedDealer()?.dealerInfo">
                     <li>
                        <a tabindex="-1" routerLink="/{{salesSchoolRegistrationRoute.uri}}" class="logout dropdown-item">Sales School Reg.</a>
                     </li>
                     <li><hr class="dropdown-divider"></li>
                     <li>
                        <a tabindex="-1" routerLink="/{{remitOnlineUri}}" class="logout dropdown-item">Remit Online</a>
                     </li>
                     <li><hr class="dropdown-divider"></li>
                  </ng-container>

                  <li>
                     <a tabindex="-1" [routerLink]="['/']" class="logout dropdown-item">Dashboard</a>
                  </li>
                  <li *ngIf="currentProfile()?.canViewDealerSearch">
                     <div class="dropdown-divider"></div>
                     <span (click)="searchDealers()"
                           class="pointable dropdown-item">Search Dealers</span>
                  </li>
                  <li><hr class="dropdown-divider"></li>
                  <li>
                     <a tabindex="-1" href="/auth/signout" class="logout dropdown-item">Logout</a>
                  </li>
               </ul>
            </li>
         </ul>
      </div>
   </div>
</nav>-->
