export interface RouteModel {
   baseUri: string
   title: string
   uri: string
}

const routes = {
   account: {
      remittance: {
         title: 'Remittances',
         baseUri: 'remit-online',
         uri: 'remit-online'
      } as RouteModel,
      title: 'Account',
      baseUri: 'account',
      uri: 'account'
   },
   merchandise_returns: {
      title: 'Merchandise Returns',
      baseUri: 'merchandise-returns',
      uri: 'merchandise-returns'
   },
   sales_school_registration: {
      title: 'Sales School Registration',
      baseUri: 'sales-school-registration',
      uri: 'sales-school-registration'
   },
   set_dealer: {
      title: '',
      baseUri: 'set-dealer',
      uri: 'set-dealer'
   } as RouteModel,
   title: 'Southwestern Advantage Dealer Portal',
   uri: ''
}

export const environment = {
   production: false,
   baseUri: "https://testportal.advantage4dealers.com",
   routes
}
