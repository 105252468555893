import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { NgIf } from '@angular/common'
import { RouterLink } from '@angular/router'
import { AppFacade } from '@appShared/services/app.facade'
import { DealerService } from '@appShared/services/dealer.service'
import { GEN2_ROUTES } from '@appShared/gen2.routes'
import { environment, RouteModel } from '@appEnvironments/environment'

@Component({
   selector: 'app-dashboard',
   templateUrl: './app-dashboard.component.html',
   styleUrls: ['./app-dashboard.component.less'],
   standalone: true,
   imports: [NgIf, RouterLink],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppDashboardComponent {
   private _routes = environment.routes
   salesSchoolRegistrationUri = this._routes.sales_school_registration.uri
   remitOnlineUri = this._routes.account.remittance.uri
   merchandiseReturnsUri = this._routes.merchandise_returns.uri

   private _appFacade = inject(AppFacade)
   currentProfile = this._appFacade.currentProfile
   salesPortalUri = this._appFacade.salesPortalUri
   dealerTrainingUri = this._appFacade.dealerTrainingUri
   clickUpUri = this._appFacade.clickUpUri
   mediaResourceUri = this._appFacade.mediaResourceCenterUri
   teamHubUri = this._appFacade.teamHubUri

   private _dealerService = inject(DealerService)
   selectedDealer = this._dealerService.selectedDealer
   canAccessSSRegistration = this._dealerService.canAccessSSRegistration

   gen2Routes = GEN2_ROUTES

   //TODO ShowOrgReporting = isOrgLeader || isOrgAssist || isManager || isDSM ? true : false;

   goToPortal(route?: RouteModel): void {
      this._appFacade.navigateToGen2(route?.uri)
   }
}
