import { environment, RouteModel } from '@appEnvironments/environment'

export const GEN2_ROUTES = {
   account_activity: {
      title: 'Account Activity',
      uri: '/legacy/account.aspx?m=1'
   } as RouteModel,
   activity_reports: {
      title: 'Activity Reports',
      uri: '/activityreports'
   } as RouteModel,
   a4d_app: {
      title: 'Adv4Dlrs App',
      uri: '/tablet'
   } as RouteModel,
   back_order_item: {
      title: 'Back Order Item',
      uri: '/backorderitem/index'
   } as RouteModel,
   cancellations: {
      title: 'Cancellations',
      uri: '/legacy/account.aspx?m=4'
   } as RouteModel,
   check_in: {
      title: 'Check-In Prep',
      uri: '/checkin/index'
   } as RouteModel,
   check_in_statement: {
      title: 'Check-In Statement',
      uri: '/checkinstatement'
   } as RouteModel,
   cost_center_expenses: {
      title: 'Cost Center Expenses',
      uri: '/costcenter/expenses'
   } as RouteModel,
   cost_center_residuals: {
      title: 'Cost Center Residuals',
      uri: '/costcenter/residuals'
   } as RouteModel,
   dealer_order_processing: {
      title: 'Online Ordering',
      uri: '/dealerorderprocessing'
   } as RouteModel,
   e_bar: {
      title: 'E-Bar',
      uri: '/ebar'
   } as RouteModel,
   electronic_forms: {
      title: 'Electronic Forms',
      uri: '/ElectronicForms'
   } as RouteModel,
   employee_resources: {
      title: 'Employee Resources',
      uri: 'https://southwesternfamily.sharepoint.com/sites/SWASalesLeaders'
   } as RouteModel,
   faq: {
      title: 'FAQ',
      uri: 'http://swdealers.uservoice.com/'
   } as RouteModel,
   headquarters_map: {
      title: 'Headquarters Map',
      uri: '/headquarters'
   } as RouteModel,
   host_family: {
      title: 'Host Family',
      uri: '/hostfamily'
   } as RouteModel,
   host_family_placement: {
      title: 'Host Placement',
      uri: '/hostfamily/placement'
   } as RouteModel,
   inventory: {
      title: 'Inventory',
      uri: '/dealerorderprocessing/inventory'
   } as RouteModel,
   merchandise_returns: {
      title: 'Merchandise Returns',
      uri: '/merchandise-returns'
   } as RouteModel,
   order_approval: {
      title: 'Order Approval',
      uri: '/dealerorderprocessing/approvalsummary'
   } as RouteModel,
   org_reporting: {
      title: 'Org Reporting',
      uri: '/orgreporting'
   } as RouteModel,
   pacesetter: {
      title: 'Pacesetter',
      uri: '/pacesetter'
   } as RouteModel,
   dealer_profile: {
      title: 'Profile',
      uri: '/account/profile'
   } as RouteModel,
   record_of_sale: {
      title: 'Record Of Sale',
      uri: '/recordofsale'
   } as RouteModel,
   reporting: {
      title: 'Reporting',
      uri: '/legacy/reportlist.aspx'
   } as RouteModel,
   settings: {
      title: 'Settings',
      uri: '/settings'
   } as RouteModel,
   shipping_calculator: {
      title: 'Shipping Calc.',
      uri: '/legacy/account.aspx?m=9'
   } as RouteModel,
   sw_capture_app: {
      title: 'SWcapture App',
      uri: '/capture'
   } as RouteModel,
   sw_stats_app: {
      title: 'SWstats App',
      uri: '/stats'
   } as RouteModel,
   tax_summary: {
      title: 'Tax Summary',
      uri: '/legacy/account.aspx?m=3'
   } as RouteModel,
   text_dealers: {
      title: 'Text Dealers',
      uri: '/textdealers'
   } as RouteModel,
   transfers: {
      title: 'Online Transfers',
      uri: '/transfers'
   } as RouteModel,
   unit_map: {
      title: 'Unit Map',
      uri: '/unitmapping'
   } as RouteModel
}
