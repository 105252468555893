
<div class="pt-4 d-flex flex-column justify-content-center">

   <div class="modules">

      <div class="module pointable">
         <span (click)="goToPortal(gen2Routes.record_of_sale)" class="pointable">
            <img src="/assets/images/dashboard/record-of-sales.png" class="w-100" />
         </span>
      </div>

      <div class="module pointable">
         <img src="/assets/images/dashboard/manage-account.png" class="w-100" />
      </div>

      <div class="module pointable">
         <span (click)="goToPortal(gen2Routes.activity_reports)" class="pointable">
            <img src="/assets/images/dashboard/activity-reports.png" class="w-100" />
         </span>
      </div>

      <div class="module pointable">
         <span (click)="goToPortal(gen2Routes.e_bar)" class="pointable">
            <img src="/assets/images/dashboard/e-bar.png" class="w-100" />
         </span>
      </div>

      <div class="module pointable">
         <a href="{{mediaResourceUri()}}" target="_blank">
            <img src="/assets/images/dashboard/media-resource-center.png" class="w-100" />
         </a>
      </div>

      <div class="module pointable">
         <a href="{{dealerTrainingUri()}}" target="_blank">
            <img src="/assets/images/dashboard/training-courses.png" class="w-100" />
         </a>
      </div>

      <div class="module pointable">
         <a href="{{teamHubUri()}}" target="_blank">
            <img src="/assets/images/dashboard/team-hub.png" class="w-100" />
         </a>
      </div>

      <div class="module pointable">
         <a href="{{clickUpUri()}}" target="_blank">
            <img src="/assets/images/dashboard/click-up.png" class="w-100" />
         </a>
      </div>

   </div>

   <div class="d-flex justify-content-center">
      <span (click)="goToPortal(gen2Routes.org_reporting)" class="pointable">
         <img src="/assets/images/dashboard/org-reporting_button.png" class="org-reporting" />
      </span>
   </div>

</div>
