import { inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { CommonService } from '@appShared/services/common.service'
import { AppFacade } from '@appShared/services/app.facade'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class MerchandiseReturnsAuthGuardService {
   private _commonService = inject(CommonService)
   private _appFacade = inject(AppFacade)
   private _router = inject(Router)

   canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

      const currentProfile = this._appFacade.currentProfile()
      if (!(currentProfile?.userPrincipalId)) {
         const returnUrl = this._commonService.getResolvedUrl(route)
         this._commonService.navigateToLogin(returnUrl)
         return of(false)
      }

      if (!currentProfile.canViewPendingMerchandiseReturns) {
         this._commonService.pageNotAuthorized()
         this._router.navigate([environment.routes.uri])

         return of(false)
      }

      return of(true)
   }
}
