import { inject, Injectable } from '@angular/core'
import { Observable, catchError, of, switchMap, take, tap } from 'rxjs'
import { AppFacade } from '@appShared/services/app.facade'
import { DealerService } from '@appShared/services/dealer.service'
import { AuthService } from '@appShared/services/auth.service'
import { environment } from '@appEnvironments/environment'

@Injectable({ providedIn: 'root' })
export class AppConfigProvider {
   private _authService = inject(AuthService)
   private _appFacade = inject(AppFacade)
   private _dealerService = inject(DealerService)

   init(): Observable<boolean> {

      this._prepareRouteUris()

      return this._authService.getSiteConfig().pipe(
         take(1),
         switchMap((siteConfig) => this._appFacade.setSiteConfig(siteConfig)),
         catchError(error => of(false))
      )
   }

   private _prepareRouteUris(): void {

      /* iterative method to loop over route objects
         to create usuable uris for ease of routing
         and no need to concatenate routes in code
      */

      let setConditionalUri = (parentUri?: any, uri?: any): string => {
         return parentUri
            ? uri
               ? `${parentUri}/${uri}`
               : parentUri
            : uri
               ? uri
               : ''
      }

      let setUrl = (obj: any, parentBaseUri?: any): void => {
         let baseUri = obj.baseUri

         Object.entries(obj)
            .forEach(([key, value]) => {
               if (typeof value === "object") {

                  // build "baseUri" to pass to children objects
                  baseUri = setConditionalUri(parentBaseUri, obj.baseUri)

                  setUrl(value, baseUri)

               } else if (key === 'uri') {

                  // set uri based on parent routes
                  obj[key] = setConditionalUri(parentBaseUri, value)
               }
            })
      }

      let routes = environment.routes

      setUrl(routes)
   }
}
